import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "./common/page";

function HealthPlanPreviewVideo() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  return (
    <Page
      className="health-plan-video"
      title="Health Plan Preview"
      description="Watch this short video to get a quick overview of our Health Plans!"
    >
      <div className="container">
        <iframe
          title="Health Plan Overview"
          src="https://www.loom.com/embed/26d00582f5224bbcb646702f3fea0c1a?sid=b5e5d9c2-412c-41f0-86b9-4708fd5d3291"
          frameborder="0"
          webkitAllowFullscreen
          mozAllowFullscreen
          allowFullScreen
        ></iframe>
      </div>
    </Page>
  );
}
export default HealthPlanPreviewVideo;
